import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { LogOut, RestoreAuthentication } from '../authentication/state/authentication.action';
import { AuthenticationState } from '../authentication/state/authentication.reducer';
import { PermissionsService } from '../shared/permisions/permissions.service';
import { SetUserDetails } from '../shared/state/user/user.actions';
import { getAccountState, hasBackOfficePermission, hasClientPermission, UserState } from '../shared/state/user/user.reducer';
import { ResetAppState } from '../state/app.actions';
import { UserDetails } from '../_models/identity-models/identity-detail';
import { UserDetailsInterface } from '../_models/identity-models/user-details.interface';
import { StorageKey } from '../_models/local-storage-key';
import { BaseApiUrl } from './base-api-urls';
import { BusinessOwnerService } from './facebook-accounts/business-owner.service';
import { GoogleService } from './google/google.service';
import { TokenService } from './token.service';
import { UserStateEnum } from './user/user-state.enum';
import { OutreachService } from '../social-media-influencer/modules/outreach-v2/services/outreach.service';
import { DiscoveryService } from '../social-media-influencer/modules/discovery-v2/services/discovery.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	public logoutSubject: Subject<void> = new Subject();
	public setRoute$: BehaviorSubject<string> = new BehaviorSubject('');
	public allowedMegaRoutes$: BehaviorSubject<any[]> = new BehaviorSubject(null);
	public triggerNavRoute$: BehaviorSubject<string> = new BehaviorSubject('audience');
	public bigCommerceAction$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setSubNavIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
	public expandMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public navMenuRoute$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setMegaRouteIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
	public setParentRoute$: BehaviorSubject<string> = new BehaviorSubject('');
	private signInLoaderState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private userDetails: UserDetails;

	constructor(
		private router: Router,
		private tokenService: TokenService,
		private permissionsService: PermissionsService,
		private outreachService: OutreachService,
		private discoveryService: DiscoveryService,
		private userStore: Store<UserState>,
		private googleService: GoogleService,
		private authStore: Store<AuthenticationState>,
		private businessOwnerService: BusinessOwnerService,
		private http: HttpClient
	) {}

	public signInLoaderStateChange(state: boolean): void {
		this.signInLoaderState$.next(state);
	}

	public getSignInLoaderState(): Observable<boolean> {
		return this.signInLoaderState$.asObservable();
	}

	public isLoggedIn() {
		const tokenExists: boolean = !!localStorage.getItem(StorageKey.token);
		if (tokenExists) {
			const decodedJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
			if (!decodedJwt) {
				return false;
			}
			const currentTime = moment().format();
			const timeFromJwt = moment(decodedJwt.exp * 1000).format();
			const decodedJwtIsValid: boolean = !moment(currentTime).isAfter(timeFromJwt);
			this.initUserDetails();

			return tokenExists && decodedJwtIsValid;
		}
	}
	public initUserDetails(): void {
		if (!this.userDetails) {
			const decodedToken = this.tokenService.decodeToken(localStorage.getItem(StorageKey.token));
			this.userDetails = this.getUserDetailsFromJwt(decodedToken);
			this.userStore.dispatch(new SetUserDetails(this.userDetails));
		}
	}

	public hasCreditCard(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState !== UserStateEnum.NoCreditCard && accountState !== UserStateEnum.FreeTrialExpiredNoCreditCard))
		);
	}

	public hasBusinessOwner(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => {
				return of(accountState !== UserStateEnum.NoBusinessOwner);
			})
		);
	}

	public hasClientEmployee(): Observable<boolean> {
		return this.userStore.pipe(
			select(hasClientPermission),
			take(1),
			switchMap(isClientEmployee => of(isClientEmployee))
		);
	}

	public isAdmin(): Observable<boolean> {
		return this.userStore.pipe(select(hasBackOfficePermission), take(1));
	}

	public isFreemiumExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState === UserStateEnum.FreemiumExpiredNoCreditCard))
		);
	}

	public isFreeTrialExpired(): Observable<boolean> {
		return this.userStore.pipe(
			select(getAccountState),
			take(1),
			switchMap(accountState => of(accountState === UserStateEnum.FreeTrialExpiredNoCreditCard))
		);
	}

	public logout(params?: NavigationExtras): void {
		// TODO hack because we dont have refresh token
		const temporaryCredentials = localStorage.getItem('details');
		localStorage.clear();
		this.clearAllModuleStates();
		if (temporaryCredentials) {
			localStorage.setItem('details', temporaryCredentials);
		}

		this.logoutSubject.next();

		this.userStore.dispatch(new ResetAppState());
		this.authStore.dispatch(new LogOut());

		params ? this.router.navigate(['/authentication'], params) : this.router.navigate(['/authentication']);
	}

	public clearAllModuleStates(): void {
		this.discoveryService.clearState();
		this.outreachService.clearState();
	}

	public logoutWithRedirect(url?: String, params?: NavigationExtras): void {
		// TODO hack because we dont have refresh token
		const temporaryCredentials = localStorage.getItem('details');
		localStorage.clear();
		if (temporaryCredentials) {
			localStorage.setItem('details', temporaryCredentials);
		}

		this.logoutSubject.next();
		this.userStore.dispatch(new ResetAppState());
		this.authStore.dispatch(new LogOut());

		let uri = url || '/authentication';
		params ? this.router.navigate([uri], params) : this.router.navigate([uri]);
	}

	public async initUserRolesLogic(): Promise<void> {
		this.tokenService.token = await localStorage.getItem(StorageKey.token);
		if (!this.tokenService.token) {
			return;
		}

		try {
			this.authStore.dispatch(new RestoreAuthentication(this.tokenService.token));
		} catch (e) {
			this.logout();
		}
	}
	public getUserDetailsFromJwt(decodedJwt: UserDetailsInterface): UserDetails {
		let googleIntegratedStatus;
		let itemString: string = localStorage.getItem(StorageKey.installedPlatorm);
		if (!!itemString && JSON.parse(itemString)?.includes('Google')) {
			googleIntegratedStatus = decodedJwt.user_filed_id;
		}
		const codedPermissions: string[] = decodedJwt.permissions_filed.split('|');
		const decodedPermissions = codedPermissions
			.map(permission => this.permissionsService.decodePermission(permission))
			.filter(permission => permission.permissions.length);
		return {
			FiledId: parseInt(decodedJwt.user_filed_id),
			OriginalFiledId: parseInt(decodedJwt.original_filed_id),
			AccountState: parseInt(decodedJwt.user_account_state),
			FacebookBusinessOwnerId: decodedJwt.user_facebook_businessowner_id,
			GoogleBusinessOwnerId: googleIntegratedStatus,
			IsFrontOfficeUser: decodedJwt.user_is_frontoffice_user === 'True',
			RejectedReason: parseInt(decodedJwt.user_rejected_reason),
			IsImpersonated: false,
			Permissions: decodedPermissions
		};
	}

	public addSubUser(email: string, token: string): Observable<any> {
		let form = new FormData();
		form.append('username', email);
		return this.http.post(`${BaseApiUrl.SocialInfluencerPython}users/add-sub-user`, form, { headers: { Authorization: token } });
	}

	public signUpSubUser(payload: FormData): Observable<any> {
		return this.http.post(`${BaseApiUrl.SocialInfluencerPython}users/sub-user-sign-up`, payload);
	}

	public authGoogle(redirect_url: string = ''): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}oauth/gmail/install${redirect_url}`);
	}

	public authPreInstall(platform: string, redirect_url: string = ''): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}oauth/${platform}/preinstall${redirect_url}`);
	}

	public authInstall(token: string, platform: string, accessToken: string): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}oauth/${platform}/install?access_token=${accessToken}`, {
			headers: { Authorization: token }
		});
	}

	public authUnInstall(platform: string): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialInfluencerPython}oauth/${platform}/uninstall`);
	}

	public authMakeDefault(payload): Observable<any> {
		return this.http.patch(`${BaseApiUrl.SocialMessengerPython}emails`, payload);
	}

	public authUnInstallV2(platform: string, email: string): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}oauth/${platform}/uninstall?email=${email}`);
	}

	public checkIntegrationStatus(token: string): Observable<{ google: string; outlook: string }> {
		return this.http.get<{ google: string; outlook: string }>(`${BaseApiUrl.SocialInfluencerPython}users/connected-mail`, {
			headers: { Authorization: token }
		});
	}

	public getConnectedEmails(): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}emails`);
	}

	public verifyEmail(token: string): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}auth/verify?token=${token}`, {});
	}

	public updateChargeIdShopifyBilling(charge_id): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}subscriptions/shopify/billing?charge_id=${charge_id}`, {});
	}
}
