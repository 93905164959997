import { Component, Input, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ShortNumberPipe } from 'src/app/shared/pipes/shortnumber-pipe';
import { getDefaultScaleConfig } from 'src/app/shared/utils';
import { GenericChartDataModel } from '../models/chart.model';
Chart.register(...registerables);

@Component({
	selector: 'app-generic-bar-graph',
	templateUrl: './generic-bar-graph.component.html',
	styleUrls: ['./generic-bar-graph.component.scss']
})
export class GenericBarGraphComponent implements OnInit {
	@Input() public chartId: string = 'default-chart-id';
	@Input() public chartData: GenericChartDataModel;
	@Input() public chartHeight?: string = '100%';
	@Input() public chartWidth?: string = '100%';
	@Input() public chartXAxisLabel?: string;
	@Input() public chartYAxisLabel?: string;
	@Input() public chartXAxisCallback?: Function;
	@Input() public chartYAxisCallback?: Function;

	public barChart: any = Chart;

	constructor(private shortNumberPipe: ShortNumberPipe) {}

	ngOnInit(): void {
		this.chartData = {
			labels: ['asd', 'asd', 'asd'],
			datasets: [
				{
					label: 'Something',
					backgroundColor: '#FD9B3D',
					borderColor: '#FD9B3D',
					borderRadius: 5,
					hoverBackgroundColor: '#FD9B3D',
					barPercentage: 0.3,
					barThickness: 19,
					data: [12, 20, 40, 60]
				},
				{
					label: 'Something',
					backgroundColor: '#e0ff64',
					borderColor: '#e0ff64',
					borderRadius: 5,
					hoverBackgroundColor: '#e0ff64',
					barPercentage: 0.3,
					barThickness: 19,
					data: [54, 22, 11, 24]
				}
			]
		};
	}

	ngAfterViewInit(): void {
		this.generateChart();
	}

	public generateChart(): void {
		var chartExist = Chart.getChart(this.chartId); // <canvas> id
		if (chartExist != undefined) chartExist.destroy();

		const chartPluginData: any = {
			legend: {
				display: false
			},
			tooltip: {
				position: 'nearest',
				backgroundColor: '#ffffff',
				bodyColor: '#121212',
				yAlign: 'bottom',
				xAlign: 'center',
				callbacks: {
					title: item => {
						return null;
					},
					label: item => {
						return `${this.shortNumberPipe.transform((item?.raw ?? 0) as number)}%`;
					}
				},
				displayColors: false
				// enabled: false,
				// external: context => this.customTooltip(context, '', {}, 'bar')
			}
		};

		if (!this.chartData) {
			return;
		}
		const chartData: any = {
			labels: this.chartData.labels,
			datasets: this.chartData.datasets
		};

		this.barChart = new Chart(this.chartId, {
			type: 'bar',
			data: chartData,
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scales: getDefaultScaleConfig(this.chartXAxisLabel, this.chartYAxisLabel, this.chartYAxisCallback, this.chartXAxisCallback),
				plugins: chartPluginData
			}
		});
	}

	ngOnDestroy(): void {
		if (this.barChart) {
			this.barChart.destroy();
		}
	}
}
