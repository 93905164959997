import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { ShortNumberPipe } from 'src/app/shared/pipes/shortnumber-pipe';
import { getDefaultScaleConfig } from 'src/app/shared/utils';
import { GenericChartDataModel } from '../models/chart.model';

@Component({
	selector: 'app-generic-pie-graph',
	templateUrl: './generic-pie-graph.component.html',
	styleUrls: ['./generic-pie-graph.component.scss']
})
export class GenericPieGraphComponent implements OnInit {
	@Input() public chartId: string = 'default-chart-id';
	@Input() public chartData: GenericChartDataModel;
	@Input() public chartHeight?: string = '100%';
	@Input() public chartWidth?: string = '100%';
	@Input() public chartXAxisLabel?: string;
	@Input() public chartYAxisLabel?: string;
	@Input() public chartXAxisCallback?: Function;
	@Input() public chartYAxisCallback?: Function;

	public donutChart: any = Chart;

	constructor(private shortNumberPipe: ShortNumberPipe) {}

	ngOnInit(): void {
		this.chartData = {
			labels: ['asd', 'asd', 'asd'],
			datasets: [
				{
					label: 'Audience Type',
					borderWidth: 0,
					cutout: '62%',
					backgroundColor: ['#e0ff64', '#ababab'],
					hoverBackgroundColor: ['#e0ff64', '#ababab'],
					hoverOffset: 0,
					data: [12, 20, 40, 60]
				},
				{
					label: 'Audience Type',
					borderWidth: 0,
					cutout: '62%',
					backgroundColor: ['#FFC0CB', '#FFFF00'],
					hoverBackgroundColor: ['#FFC0CB', '#FFFF00'],
					hoverOffset: 0,
					data: [33, 11, 65, 33]
				}
			]
		};
	}

	ngAfterViewInit(): void {
		this.generateChart();
	}

	private generateChart(): void {
		let generatedChartData: any = {
			labels: this.chartData.labels,
			datasets: this.chartData.datasets
		};
		var chartExist = Chart.getChart(this.chartId); // <canvas> id
		if (chartExist != undefined) chartExist.destroy();

		this.donutChart = new Chart(this.chartId, {
			type: 'doughnut',
			data: generatedChartData,
			options: {
				responsive: true,
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						callbacks: {
							label: context => `${context.parsed}%`
						}
					}
				}
			}
		});
	}

	ngOnDestroy(): void {
		if (this.donutChart) {
			this.donutChart.destroy();
		}
	}
}
