import { AfterViewInit, ChangeDetectorRef, Component, isDevMode, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { BillingAndSubscriptionService } from 'src/app/shared/services/billing-and-subscriptions.service';
import { AppState } from 'src/app/state/app.state';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { GoogleService } from 'src/app/_services/google/google.service';
import { environment } from 'src/environments/environment';
import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { LogIn, LogInSuccess } from '../state/authentication.action';
import { AuthenticationState } from '../state/authentication.reducer';
import { LogInModel } from '../_models/login.model';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { SharedService } from 'src/app/shared/services/shared.service';

declare var google: any;

@Component({
	selector: 'app-signin',
	templateUrl: './signin-new.component.html',
	styleUrls: ['./signin-new.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy, AfterViewInit {
	public signInForm: UntypedFormGroup;
	public emFormControl: UntypedFormControl;
	public passFormControl: UntypedFormControl;
	public isAccountSignedIn = false;
	public loading = false;
	public hasRedirectParam: boolean;
	public validationMessages: ValidatorMessages[];
	public isFromBigCommerce = false;
	private unsubscriber$ = new Subject<void>();
	public subscribeToBillingId: any;
	public submitting: boolean = false;
	private googleCientId = environment.Google.ClientId;
	public productId: string;
	public priceID: string;
	public credits: number;
	public isLoggedin: boolean;
	public shpfyact: string = '';

	constructor(
		private router: Router,
		private authStore: Store<AuthenticationState>,
		private toastNotificationService: ToastNotificationService,
		private bilingService: BillingAndSubscriptionService,
		public dialog: MatDialog,
		private googleService: GoogleService,
		private ngZone: NgZone,
		private authService: AuthenticationService,
		private sharedService: SharedService,
		private activatedRoute: ActivatedRoute,
		private ref: ChangeDetectorRef
	) {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params['shpfyact']) {
				this.shpfyact = params['shpfyact'];
			}

			if (params?.['fromShopify']) {
				this.sharedService.shopifyPayload = {
					shop: params?.['shop'],
					code: params?.['code'],
					state: params?.['state'],
					hmac: params?.['hmac'],
					host: params?.['host'],
					timestamp: params?.['timestamp']
				};
			}
		});
	}

	public ngOnInit(): void {
		const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

		this.emFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(emailValidator), Validators.maxLength(70)]);
		this.passFormControl = new UntypedFormControl('', [Validators.required]);

		this.signInForm = new UntypedFormGroup({
			email: this.emFormControl,
			password: this.passFormControl
		});

		this.authService
			.getSignInLoaderState()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				this.loading = res;
			});

		this.bilingService
			.getSubcribeToId()
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.subscribeToBillingId = isDevMode() ? '1' : '35';
					return EMPTY;
				})
			)
			.subscribe(value => {
				if (value) {
					this.subscribeToBillingId = value;
				} else {
					this.subscribeToBillingId = isDevMode() ? '1' : '35';
				}
			});
	}

	public onForgotPasswordClick(): void {
		this.router.navigate(['/authentication/reset-password-with-email']);
	}

	public onSubmit(): void {
		if (this.signInForm.invalid) {
			this.signInForm.markAllAsTouched();
			return;
		}

		const temporaryLoginEmail = this.emFormControl.value.trim();
		const temporaryLoginPassword = this.passFormControl.value;

		const loginPayload: LogInModel = {
			email: temporaryLoginEmail.toLowerCase(),
			password: temporaryLoginPassword,
			code: ''
		};

		this.authService.signInLoaderStateChange(true);
		this.ngZone.run(() => {
			this.authStore.dispatch(new LogIn(loginPayload));
		});
		this.toggleLoader();
	}

	private toggleLoader(): void {}

	public onSubscribeToAnOffer(): void {
		if (this.shpfyact == '') {
			location.href = '/authentication/register-email?without_cc=true';
		} else {
			location.href = `/authentication/register-email?shpfyact=${this.shpfyact}`;
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	ngAfterViewInit(): void {
		google.accounts.id.initialize({
			client_id: this.googleCientId,
			callback: (response: any) => this.signUpWithGoogle(response),
			ux_mode: 'popup'
		});
		google.accounts.id.renderButton(
			document.getElementById('google-btn'),
			{ size: 'rectangular', width: '300px', height: '1px' } // customization attributes
		);
	}

	signUpWithGoogle(token): void {
		this.submitting = true;
		this.ref.detectChanges();
		this.authService.signInLoaderStateChange(true);
		if (token) {
			this.googleService
				.googleSignIn(token.credential)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					res => {
						if (typeof res === 'string') {
							this.authStore.dispatch(new LogInSuccess(res));
							this.toggleLoader();
						} else if (res?.status === false) {
							location.href = location.href + '/register-email';
						} else {
							this.toastNotificationService.sendErrorToast('Error Signing you In, Please try later');
						}
					},
					err => {
						if (err?.status == 401) {
							this.toastNotificationService.sendErrorToast(err?.error?.detail?.message, 999999);
						} else {
							this.toastNotificationService.sendErrorToast('Error Signing you In, Please try later');
						}
						this.authService.signInLoaderStateChange(false);
					}
				);
		}
	}
}
